









import {Store} from '@/store/store'
import Component from 'vue-class-component'
import EditEmail from '@/components/forms/EditEmail.vue'
import EditProfile from '@/components/forms/EditProfile.vue'

@Component({
  components: {
    EditEmail, 
    EditProfile
  },
  metaInfo: {
    title: 'Business Profile',
  }
})
export default class Profile extends Store {
  componentTitle = 'Business Profile';
  
  email?: string  = ''
  passwordPopupIsVisible?: boolean = false
};
